import core from "../../api/core.js";

// initial state
const state = () => ({
  isProvisioned: localStorage.getItem("isProvisioned") === "true",
});

// actions
const actions = {
  fetchProvisionedState({ commit }) {
    return new Promise((resolve, reject) => {
      core
        .headConfig()
        .then((response) => {
          if (response.status === 200) {
            commit("setProvisionedState", true);
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((err) => {
          reject(err.response.status);
        });
    });
  },
  isProvisioned({ dispatch, state }) {
    return new Promise((resolve, reject) => {
      if (state.isProvisioned) {
        resolve(true);
      } else {
        dispatch("fetchProvisionedState")
          .then((provisionedState) => {
            resolve(provisionedState);
          })
          .catch((status) => {
            reject(status);
          });
      }
    });
  },
  clearProvisionedState({ commit }) {
    commit("clearProvisionedState");
  },
};

// mutations
const mutations = {
  setProvisionedState(state, isProvisioned) {
    state.isProvisioned = isProvisioned;
    localStorage.setItem("isProvisioned", state.isProvisioned);
  },
  clearProvisionedState(state) {
    state.isProvisioned = false;
    localStorage.removeItem("isProvisioned");
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
