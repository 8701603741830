import host from "../../api/host.js";

// initial state
const state = () => ({
  isSelfHosted: null,
});

// actions
const actions = {
  fetchHostConfiguration({ commit }) {
    return new Promise((resolve, reject) => {
      host.getHostConfiguration().then(
        (response) => {
          commit("setHostConfiguration", response.data);
          localStorage.setItem("isSelfHosted", response.data.self_hosted);
          resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  isSelfHosted({ dispatch, state }) {
    return new Promise((resolve, reject) => {
      if (state.isSelfHosted) {
        resolve(state.isSelfHosted);
      } else if (localStorage.getItem("isSelfHosted") !== null) {
        resolve(localStorage.getItem("isSelfHosted") == "true");
      } else {
        dispatch("fetchHostConfiguration")
          .then((hostConfiguration) => {
            resolve(hostConfiguration.self_hosted);
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  },
};

// mutations
const mutations = {
  setHostConfiguration(state, hostConfiguration) {
    state.isSelfHosted = hostConfiguration.self_hosted;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
