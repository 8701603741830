<template>
  <el-container class="page-container">
    <div v-if="loading" id="fullscreenLoadMount"></div>
    <el-aside
      v-if="
        currentRouteName != 'LoginView' &&
        currentRouteName != 'WorkerNotSupportedView' &&
        currentRouteName != 'ProvisioningView' &&
        currentRouteName
      "
    >
      <SideMenu />
    </el-aside>
    <el-main>
      <router-view />
    </el-main>
  </el-container>
</template>

<script>
import SideMenu from "@/components/SideMenu.vue";
export default {
  name: "App",
  components: { SideMenu },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title
          ? `${to.meta.title} | Tait PTToX`
          : "Tait PTToX";
      },
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.loading = false;
    });
  },
};
</script>
