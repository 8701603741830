import store from "../store";
import router from "@/router/index.js";
import { ElNotification } from "element-plus";
import serviceWorkerHelper from "@/utils/serviceWorkerHelper.js";
export default {
  data() {
    return {
      pokeInterval: null,
    };
  },
  setupWorker() {
    let parent = this;
    return new Promise(function (resolve, reject) {
      // Check service workers are supported
      if ("serviceWorker" in navigator) {
        // Check if a service worker is already registered and active
        navigator.serviceWorker
          .getRegistration()
          .then((reg) => {
            if (parent.workerActive(reg)) resolve();
            else {
              console.log(
                "Unregistering inactive service worker and starting a new one."
              );
              reg.unregister().then((wasSuccessful) => {
                if (wasSuccessful)
                  parent
                    .startWorker()
                    .then(() => resolve())
                    .catch(() => reject());
                else
                  console.log("Failed to unregister inactive service worker.");
              });
            }
          })
          .catch(() => {
            parent
              .startWorker()
              .then(() => resolve())
              .catch(() => reject());
          });
      } else {
        console.log(
          "Service workers are not supported by the browser. The website will not function correctly without them."
        );
        reject("notSupported");
      }
    });
  },
  startWorker() {
    let parent = this;
    return new Promise(function (resolve, reject) {
      navigator.serviceWorker
        .register("/service-worker.js", {
          scope: "/",
        })
        .then(() => navigator.serviceWorker.ready)
        .then(() => {
          navigator.serviceWorker
            .getRegistration()
            .then(function (reg) {
              if (parent.workerActive(reg)) resolve();
              else reject();
            })
            .catch(() => {
              reject();
            });
        })
        .catch((err) => {
          console.log(`ServiceWorker registration failed: ${err}`);
          reject();
        });
    });
  },
  workerActive(reg) {
    if (reg.active) {
      if (navigator.serviceWorker.controller) {
        return true;
      } else {
        // There's an active SW, but no controller for this tab.
        // Perform a soft reload to load everything from the SW and get
        // a consistent set of resources.
        window.location.reload();
      }
    } else {
      return false;
    }
  },
  startPeriodicPoke() {
    let parent = this;
    if (this.pokeInterval) clearInterval(this.pokeInterval);
    return new Promise(function (resolve) {
      parent.pokeInterval = setInterval(pokeHandler, 1000);
      function pokeHandler() {
        parent.pokeWorker().catch(() => {
          parent.stopPeriodicPoke();
          resolve();
        });
      }
    });
  },
  stopPeriodicPoke() {
    if (this.pokeInterval) clearInterval(this.pokeInterval);
  },
  pokeWorker() {
    let parent = this;
    return new Promise(function (resolve, reject) {
      parent
        .sendMessage({ command: "poke" })
        .then(function () {
          resolve();
        })
        .catch(function () {
          console.log("Service worker poke failed.");
          reject();
        });
    });
  },
  sendMessage(message) {
    return new Promise(function (resolve, reject) {
      var messageChannel = new MessageChannel();
      let responseReceived = false;
      messageChannel.port1.onmessage = function (event) {
        responseReceived = true;
        if (event.data) {
          resolve(event.data);
        } else {
          reject();
        }
      };
      navigator.serviceWorker.controller.postMessage(message, [
        messageChannel.port2,
      ]);
      setTimeout(() => {
        if (!responseReceived) {
          console.log("Timed out sending message to service worker.");
          reject();
        }
      }, "2000");
    });
  },
  loadSession() {
    let parent = this;
    return new Promise(function (resolve) {
      parent
        .sendMessage({ command: "getSessionData" })
        .then(function (data) {
          console.log("Existing session found");
          store.commit("auth/setSession", data);
          resolve();
        })
        .catch(function () {
          console.log("No existing session found");
          resolve();
        });
    });
  },
  resetWorker() {
    this.sendMessage({ command: "resetToken" })
      .then(function () {
        console.log("Service worker reset");
      })
      .catch(function () {
        console.log("Failed to reset service worker");
      });
  },
  logoutWithError(message) {
    store.dispatch("auth/logout").then(() => {
      serviceWorkerHelper.resetWorker();
      router.push({ name: "LoginView" });
    });
    ElNotification({
      title: "Error",
      message: message,
      type: "error",
    });
  },
};
