export default {
  // Button text
  reload_page_button: "Reload page",
  cancel_button: "Cancel",
  create_button: "Create",
  delete_button: "Delete",
  delete_rule_button: "Delete rule",
  create_new_rule_button: "Create new rule",

  // Page category text
  monitoring_category: "Monitoring",
  telephony_category: "Telephony",
  administration_category: "Administration",

  // Page title text
  system_status_page_title: "System status",
  records_page_title: "Records",
  logs_page_title: "Logs",
  devices_page_title: "Devices",
  gateway_setup_page_title: "Gateway setup",
  inbound_page_title: "Inbound",
  outbound_page_title: "Outbound",
  pabx_page_title: "PABX",
  direct_dialling_page_title: "Direct dialling",
  diversion_page_title: "Diversion",
  call_options_page_title: "Call options",
  manage_users_page_title: "Manage users",
  backups_page_title: "Backups",
  client_cas_page_title: "Client CAs",
  server_configuration_page_title: "Server configuration",
  logout_page_title: "Logout",
  login_page_title: "Login",
  no_worker_page_title: "Worker not supported",
  provisioning_page_title: "Provisioning",

  // Info text
  direct_dialling_info:
    "Map a unique phone number to a PTToX unit so that it can be dialled directly. Outbound PSTN calls will also use the mapped number in their caller ID. Direct dialling is unaffected by inbound rules.",
  direct_dialling_create_info:
    "Only phone numbers registered in your PSTN gateway will work for direct dialling. Phone numbers must be entered in the format required by your provider.",
  dmr_native_id_info: "Radio IDs must entered in DMR native format.",
  wildcard_character_info:
    "Use the wildcard character (*) to capture everything after a given extension. Eg: 20* will capture 206, 2014, etc...",

  // Page content text
  operations_col_title: "Operations",
  phone_num_col_title: "Phone number",
  radio_id_col_title: "Radio ID",
  confirm_enable_public_access:
    "Are you sure you want to enable public access to the web portal and supporting API endpoints?",
  confirm_disable_public_access:
    "Are you sure you want to disable public access to the web portal and supporting API endpoints? If you are currently accessing the portal from a public IP address, access will be lost.",
  resolve_ip_confirm:
    "Are you sure you want to update the IP by resolving the current domain? Incorrect configuration will result in a loss of service.",
  rule_delete_confirmation:
    "Are you sure you want to delete the following rule?",

  // Modal title text
  direct_dialling_create_modal_title: "Create direct dialling rule",
  direct_dialling_delete_modal_title: "Delete direct dialling rule",
  confirm_enable_public_access_title: "Enable public access",
  confirm_disable_public_access_title: "Disable public access",

  // Validation text
  invalid_domain: "Domain is invalid.",
  invalid_pem: "Invalid PEM.",
  invalid_backup_file: "Must be a valid tar.gz file.",
  invalid_backup_filename:
    "Invalid filename. May contain any of a-zA-Z0-9._- beginning with an alphanumeric character.",
  backups_full: "Maximum number of backups reached.",

  // Notification text
  domain: "Domain",
  pttox_certificate: "PTToX Certificate",
  https_certificate: "HTTPS Certificate",
  pttox_key: "PTToX Key",
  https_key: "HTTPS Key",
  all: "Error",
  domain_not_match: "Domain does not match certificate.",
  domain_does_not_resolve: "Domain does not resolve to valid IP.",
  invalid_certificate: "Certificate is invalid.",
  invalid_pttox_certificate: "PTToX certificate is invalid.",
  invalid_https_certificate: "HTTPS certificate is invalid.",
  invalid_certificate_chain_ca:
    "Invalid certificate chain, certificate may not be signed by a valid CA.",
  invalid_content: "Contains invalid content.",
  invalid_certificate_chain: "Invalid certificate chain.",
  no_root_certificate: "No root certificate found.",
  invalid_sans: "Invalid SANS.",
  invalid_certificate_key_pair:
    "The certificate key pair does not match or they do not contain valid PEM content.",
  pttox_certificate_not_tait_signed: "PTToX certificate is not Tait signed.",
  https_certificate_is_tait_signed:
    "HTTPS certificate is Tait signed, this may be the PTToX certificate.",
  certificate_key_both_required:
    "Changes to one of certificate key pair, requires a change to both.",
  initial_provisioning_requires_all:
    "Initial provisioning requires all fields to be set.",
  ca_exists_uploading: "Certificate already exists.",
  ca_error_uploading: "Failed to upload certificate.",
  ca_load_failure: "Failed to load trusted CAs.",
  ca_upload_success: "Successfully uploaded certificate.",
  ca_delete_success: "Successfully deleted certificate.",
  ca_error_deleting: "Error deleting certificate.",
  upload_backup_success: "Backup uploaded.",
  delete_backup_success: "Backup deleted.",
  create_backup_status_success: "Backup created.",
  restore_backup_status_success: "Backup restored.",
  upload_backup_failure: "Failed to upload backup.",
  upload_backup_invalid_file: "Invalid backup file.",
  download_backup_failure: "Failed to download backup.",
  download_backup_not_exist:
    "Failed to download backup. Backup no longer exists.",
  restore_backup_failure: "Failed to restore backup.",
  create_backup_failure: "Failed to create backup.",
  delete_backup_failure: "Failed to delete backup.",
  load_backup_failure: "Failed to load backups.",
  load_backup_status_failure: "Failed to retrieve backup status.",
  create_backup_status_failure: "There was a problem creating the backup.",
  restore_backup_status_failure: "There was a problem restoring the backup.",
  access_config_get_failed: "Failed to load access configuration.",
  access_config_set_failed: "Failed to save access configuration.",
  no_core_config_logout: "There was a problem loading the server configuration",
  resolve_ip_title: "Resolve IP",
  resolve_ip_failure: "Failed to resolve IP.",
  unexpected_server_response: "Unexpected server response",
  database_not_available: "Database is not available",
  unexpected_response_logout:
    "Logged out due to an unexpected response from the server. Log in to try again.",
  invalid_username_password: "Invalid username or password",
  failed_to_contact_server: "Failed to contact server",
  login_to_use_portal: "Log in again to keep using the portal.",
  session_expired: "Session expired",
  system_not_provisioned: "System not provisioned",
  admin_required_to_provision: "An admin is required to perform provisioning",
  chunk_load_failed_title: "Request failed",
  stale_instance_message:
    "Failed to load part of the web page. This is likely because the PTToX core has been updated while this tab has been open. To use the latest version, reload the page.",
  chunk_load_failed_message:
    "Failed to load part of the web page. Check your network connection and reload the page.",
  call_options_save_failed: "Failed to save call options.",
  call_options_load_failed: "Failed to load call options.",
  direct_dialling_load_failed: "Failed to load direct dialling rules.",
  direct_dialling_create_failed: "Failed to create direct dialling rule.",
  direct_dialling_create_success: "Direct dialling rule created.",
  direct_dialling_delete_success: "Direct dialling rule deleted.",
  direct_dialling_delete_failed: "Failed to delete direct dialling rule.",
  direct_dialling_no_rules_found: "No direct dialling rules found.",
};
