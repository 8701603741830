import { createStore, createLogger } from "vuex";
import auth from "./modules/auth";
import host from "./modules/host";
import core from "./modules/core";

const debug = process.env.NODE_ENV !== "production";

export default createStore({
  modules: {
    auth,
    host,
    core,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
