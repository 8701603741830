import axios from "axios";

export default {
  async login(username, password) {
    return axios.post("/api/sessions", {
      username: username,
      password: password,
    });
  },
};
