import axios from "axios";

export default {
  async headConfig() {
    return axios.head("/api/core");
  },
  async getConfig() {
    return axios.get("/api/core");
  },
  async setConfig(newConfig) {
    return axios.post("/api/core", newConfig);
  },
};
