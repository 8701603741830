import { createWebHistory, createRouter } from "vue-router";
import { ElNotification, ElMessageBox } from "element-plus";
import store from "@/store";
import strings from "@/resources/strings";
import serviceWorkerHelper from "@/utils/serviceWorkerHelper.js";

const WorkerNotSupportedView = () =>
  import("@/views/WorkerNotSupportedView.vue");
const LoginView = () => import("@/views/LoginView.vue");
const GatewaySetupView = () => import("@/views/Telephony/GatewaySetupView.vue");
const InboundView = () => import("@/views/Telephony/InboundView.vue");
const OutboundView = () => import("@/views/Telephony/OutboundView.vue");
const PabxView = () => import("@/views/Telephony/PabxView.vue");
const DirectDiallingView = () =>
  import("@/views/Telephony/DirectDiallingView.vue");
const DiversionView = () => import("@/views/DiversionView.vue");
const CallOptionsView = () => import("@/views/CallOptionsView.vue");
const DevicesView = () => import("@/views/Monitoring/DevicesView.vue");
const RecordsView = () => import("@/views/Monitoring/RecordsView.vue");
const LogsView = () => import("@/views/Monitoring/LogsView.vue");
const SystemStatusView = () =>
  import("@/views/Monitoring/SystemStatusView.vue");
const BackupView = () => import("@/views/Administration/BackupView.vue");
const ManageUsersView = () =>
  import("@/views/Administration/ManageUsersView.vue");
const CoreConfigurationView = () =>
  import("@/views/Administration/CoreConfigurationView.vue");
const ClientCAsView = () => import("@/views/Administration/ClientCAsView.vue");
const ProvisioningView = () => import("@/views/ProvisioningView.vue");

const routes = [
  {
    path: "/telephony/gateway-setup",
    name: "GatewaySetupView",
    component: GatewaySetupView,
    meta: {
      title: strings.gateway_setup_page_title,
    },
  },
  {
    path: "/telephony/inbound",
    name: "InboundView",
    component: InboundView,
    meta: {
      title: strings.inbound_page_title,
    },
  },
  {
    path: "/telephony/outbound",
    name: "OutboundView",
    component: OutboundView,
    meta: {
      title: strings.outbound_page_title,
    },
  },
  {
    path: "/telephony/pabx",
    name: "PabxView",
    component: PabxView,
    meta: {
      title: strings.pabx_page_title,
    },
  },
  {
    path: "/telephony/direct-dialling",
    name: "DirectDiallingView",
    component: DirectDiallingView,
    meta: {
      title: strings.direct_dialling_page_title,
    },
  },
  {
    path: "/diversion",
    name: "DiversionView",
    component: DiversionView,
    meta: {
      title: strings.diversion_page_title,
    },
  },
  {
    path: "/call-options",
    name: "CallOptionsView",
    component: CallOptionsView,
    meta: {
      title: strings.call_options_page_title,
    },
  },
  {
    path: "/monitoring/devices",
    name: "DevicesView",
    component: DevicesView,
    meta: {
      title: strings.devices_page_title,
    },
    beforeEnter: (to, from, next) => {
      store
        .dispatch("host/isSelfHosted")
        .then((selfHosted) => {
          if (store.getters["auth/canManageUsers"] || selfHosted) next();
          else next({ name: "SystemStatusView" });
        })
        .catch(() => {
          unexpectedResponseLogout();
        });
    },
  },
  {
    path: "/monitoring/records",
    name: "RecordsView",
    component: RecordsView,
    meta: {
      title: strings.records_page_title,
    },
  },
  {
    path: "/monitoring/logs",
    name: "LogsView",
    component: LogsView,
    meta: {
      title: strings.logs_page_title,
    },
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/canDownloadLogs"])
        next({ name: "SystemStatusView" });
      else next();
    },
  },
  {
    path: "/monitoring/status",
    name: "SystemStatusView",
    component: SystemStatusView,
    meta: {
      title: strings.system_status_page_title,
    },
  },
  {
    path: "/administration/backups",
    name: "BackupView",
    component: BackupView,
    meta: {
      title: strings.backups_page_title,
    },
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/canManageUsers"])
        next({ name: "SystemStatusView" });
      else next();
    },
  },
  {
    path: "/administration/manage-users",
    name: "ManageUsersView",
    component: ManageUsersView,
    meta: {
      title: strings.manage_users_page_title,
    },
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/canManageUsers"])
        next({ name: "SystemStatusView" });
      else next();
    },
  },
  {
    path: "/administration/server-configuration",
    name: "CoreConfigurationView",
    component: CoreConfigurationView,
    meta: {
      title: strings.server_configuration_page_title,
    },
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/canManageUsers"])
        next({ name: "SystemStatusView" });
      else next();
    },
  },
  {
    path: "/administration/client-cas",
    name: "ClientCAsView",
    component: ClientCAsView,
    meta: {
      title: strings.client_cas_page_title,
    },
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/canManageUsers"])
        next({ name: "SystemStatusView" });
      else next();
    },
  },
  {
    path: "/login",
    name: "LoginView",
    component: LoginView,
    meta: {
      title: strings.login_page_title,
    },
  },
  {
    path: "/worker-not-supported",
    name: "WorkerNotSupportedView",
    component: WorkerNotSupportedView,
    meta: {
      title: strings.no_worker_page_title,
    },
  },
  {
    path: "/provisioning",
    name: "ProvisioningView",
    component: ProvisioningView,
    meta: {
      title: strings.provisioning_page_title,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

let initialLoad = true;
let poking = false;
let workerSupported = false;
let sessionTimeout;

// -- Helper methods -- //

const startPeriodicPoke = () => {
  if (!poking) {
    poking = true;
    serviceWorkerHelper.startPeriodicPoke().then(() => {
      poking = false;
      initialLoad = true;
      sessionExpiryLogout();
    });
  }
};

const startSessionTimeout = () => {
  stopSessionTimeout();
  const timeNow = Math.floor(new Date().getTime() / 1000);
  const sessionExpiry = store.getters["auth/getExpiry"];
  const timeRemaining = (sessionExpiry - timeNow) * 1000;
  sessionTimeout = setTimeout(sessionExpiryLogout, timeRemaining);
};

const stopSessionTimeout = () => {
  if (sessionTimeout) clearTimeout(sessionTimeout);
};

const logout = () => {
  store.dispatch("auth/logout").then(() => {
    serviceWorkerHelper.resetWorker();
    router.push({ name: "LoginView" });
  });
};

// -- Notifications -- //

let sessionExpNotification;
let notProvisionedNotAdminNotification;
let databaseNotAvailableNotification;
let unexpectedResponseNotification;

const sessionExpiryLogout = () => {
  logout();
  sessionExpNotification = ElNotification({
    title: strings.session_expired,
    message: strings.login_to_use_portal,
    type: "info",
    duration: 0,
  });
};

const unexpectedResponseLogout = () => {
  logout();
  unexpectedResponseNotification = ElNotification({
    title: strings.unexpected_server_response,
    message: strings.unexpected_response_logout,
    type: "info",
    duration: 0,
  });
};

const notProvisionedNotAdminLogout = () => {
  logout();
  notProvisionedNotAdminNotification = ElNotification({
    title: strings.system_not_provisioned,
    message: strings.admin_required_to_provision,
    type: "info",
    duration: 0,
  });
};

const databaseNotAvailableLogout = () => {
  logout();
  databaseNotAvailableNotification = ElNotification({
    title: strings.unexpected_server_response,
    message: strings.database_not_available,
    type: "warning",
    duration: 0,
  });
};

const closeLogoutNotifications = () => {
  if (sessionExpNotification) sessionExpNotification.close();
  if (unexpectedResponseNotification) unexpectedResponseNotification.close();
  if (databaseNotAvailableNotification)
    databaseNotAvailableNotification.close();
  if (notProvisionedNotAdminNotification) {
    notProvisionedNotAdminNotification.close();
  }
};

const staleInstanceNotification = () => {
  ElMessageBox.confirm(
    strings.stale_instance_message,
    strings.chunk_load_failed_title,
    {
      confirmButtonText: strings.reload_page_button,
      cancelButtonText: strings.cancel_button,
      type: "warning",
    }
  )
    .then(() => {
      window.location.reload();
    })
    .catch(() => {});
};

const chunkLoadFailedNotification = () => {
  ElMessageBox.confirm(
    strings.chunk_load_failed_message,
    strings.chunk_load_failed_title,
    {
      confirmButtonText: strings.reload_page_button,
      cancelButtonText: strings.cancel_button,
      type: "warning",
    }
  )
    .then(() => {
      window.location.reload();
    })
    .catch(() => {});
};

// -- Routing rules -- //

const provisionedRouter = (to, next) => {
  if (to.name === "LoginView" || to.name === "ProvisioningView") {
    next({ name: "SystemStatusView" });
  } else if (
    to.name === "CoreConfigurationView" ||
    to.name === "RecordsView" ||
    to.name === "BackupView"
  ) {
    store
      .dispatch("host/isSelfHosted")
      .then((selfHosted) => {
        if (selfHosted) {
          next();
        } else {
          next({ name: "SystemStatusView" });
        }
      })
      .catch(() => {});
  } else if (to.path === "/") {
    next({ name: "SystemStatusView" });
  } else {
    next();
  }
};

const routingRules = (to, next) => {
  let isAuthenticated = store.getters["auth/isAuthenticated"];
  if (isAuthenticated) {
    closeLogoutNotifications();
    startPeriodicPoke();
    startSessionTimeout();
  } else {
    serviceWorkerHelper.stopPeriodicPoke();
    stopSessionTimeout();
    poking = false;
  }
  if (!workerSupported) {
    if (to.name !== "WorkerNotSupportedView") {
      next({ name: "WorkerNotSupportedView" });
    } else {
      next();
    }
  } else if (isAuthenticated) {
    store
      .dispatch("host/isSelfHosted")
      .then((result) => {
        if (result) {
          store
            .dispatch("core/isProvisioned")
            .then((isProvisioned) => {
              if (isProvisioned) {
                provisionedRouter(to, next);
              } else {
                if (store.getters["auth/canManageUsers"]) {
                  if (to.name !== "ProvisioningView") {
                    next({ name: "ProvisioningView" });
                  } else {
                    next();
                  }
                } else {
                  notProvisionedNotAdminLogout();
                }
              }
            })
            .catch((status) => {
              console.log(status);
              if (status == 503) {
                databaseNotAvailableLogout();
              } else if (status !== 401) {
                unexpectedResponseLogout();
              }
            });
        } else {
          provisionedRouter(to, next);
        }
      })
      .catch(() => {
        provisionedRouter(to, next);
      });
  } else {
    if (to.name !== "LoginView") {
      next({ name: "LoginView" });
    } else {
      next();
    }
  }
};

// -- Router hooks -- //

router.beforeEach((to, from, next) => {
  if (initialLoad) {
    initialLoad = false;
    serviceWorkerHelper
      .setupWorker()
      .then(() => {
        workerSupported = true;
        serviceWorkerHelper.loadSession().then(() => {
          routingRules(to, next);
        });
      })
      .catch((error) => {
        if (error == "notSupported") {
          next({ name: "WorkerNotSupportedView" });
        } else {
          next({ name: "LoginView" });
        }
      });
  } else {
    routingRules(to, next);
  }
});

router.onError(async (error) => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    try {
      const response = await fetch(error.request, { method: "HEAD" });
      if (response.status === 404) {
        staleInstanceNotification();
      } else {
        chunkLoadFailedNotification();
      }
    } catch (err) {
      console.error("Error checking chunk status:", err);
      chunkLoadFailedNotification();
    }
  }
});

export default router;
