<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="6 12 36.05 24">
    <path
      d="M6 36v-3h26v3Zm33.9-2.6-9.45-9.45 9.4-9.4L42 16.7l-7.25 7.25 7.3 7.3ZM6 25.4v-3h20v3ZM6 15v-3h26v3Z"
    />
  </svg>
</template>

<script>
export default {
  name: "CollapseDrawerIcon",
};
</script>
