<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="4 10 38 28">
    <path
      d="M18.5 38v-3h4q-.4-7.85-5.725-13.175Q11.45 16.5 4 16.5v-3q6.8 0 12.2 3.925Q21.6 21.35 24 27.65q1.65-4.55 4.975-8.325Q32.3 15.55 37 13h-8v-3h13v13h-3v-7.65q-6.05 3.15-9.575 8.25Q25.9 28.7 25.5 35h4v3Z"
    />
  </svg>
</template>

<script>
export default {
  name: "DiversionIcon",
};
</script>
