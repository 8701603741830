<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="160 -800 640 640">
    <path
      d="M160-160v-440h140v440H160Zm250 0v-640h140v640H410Zm250 0v-280h140v280H660Z"
    />
  </svg>
</template>

<script>
export default {
  name: "MonitoringIcon",
};
</script>
